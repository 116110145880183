<template>
  <div v-if="loaded" class="mt-2">
    <div v-if="loaded.step_id == 2">
      <a :href="'https://back.uib.kz/uploads/'+loaded.file">{{ title }}</a>
    </div>
    <div v-else>
      <a :href="'https://back.uib.kz/uploads/'+loaded.file">{{ title }}</a>
    </div>

  </div>
  <div v-else class="row mt-2">
    <div class="col-md-2">{{ title }}</div>
    <div class="col-md-3"><input type="file" ref="file" v-on:change="handleFileUpload()" :disabled="isDisabled" class="form-control"></div>
    <div class="col-md-2">
      <button @click="sendFile()" class="btn btn-primary" :disabled="isDisabled">Загрузить</button>
    </div>
  </div>
</template>

<script>
import {mapActions, mapState} from 'vuex'
export default {
  name: "ResearchCupArticle",
  data() {
    return {
      file: "",
      isDisabled: false
    }
  },
  props:{
    message: {type:String},
    title: {type:String},
    loaded: {},
    step: null,
  },
  methods: {
    ...mapActions('studentResearchCupRegistration', ['POST_RESEARCH_CUP_FILE', 'GET_STUDENT_RESEARCH_CUP_REGISTRATION']),
    async sendFile(){
      let formData = new FormData()
      formData.append('file', this.file)
      formData.append('step_id', this.researchCupSteps[this.step].id)
      for (let [key, value] of formData) {
        console.log(`${key} - ${value}`)
      }
      let result = await this.POST_RESEARCH_CUP_FILE(formData)
      if(result) {
        this.$message({title: this.message})
        window.location.reload();

      }
    },
    handleFileUpload(){
      this.file = this.$refs.file.files[0];
    }
  },
  computed: {
    ...mapState('studentResearchCupRegistration', ['researchCupSteps']),
  },
}
</script>

<style scoped>

</style>
