<template>
  <div v-if="loaded" class="mt-2">
    <a :href="loaded.file">Видео-визитка</a>
  </div>
  <div v-else class="row mt-2">
    <div class="col-md-2">Видео-визитка</div>
    <div class="col-md-3"><input type="text" v-model="videoUrl" :disabled="isDisabled" class="form-control"></div>
    <div class="col-md-2">
      <button @click="sendVideoFile()" class="btn btn-primary" :disabled="isDisabled">Загрузить</button>
    </div>
  </div>

</template>

<script>
import {mapActions, mapState} from 'vuex'

export default {
  name: "ResearchCupVideo",
  data() {
    return {
      videoUrl: "",
      isDisabled: false
    }
  },
  props:{
    loaded: {},
  },
  methods: {
    ...mapActions('studentResearchCupRegistration', ['POST_RESEARCH_CUP_FILE']),
    async sendVideoFile(){
      let formData = new FormData()
      formData.append('file', this.videoUrl)
      formData.append('step_id', this.researchCupSteps[0].id)
      console.log(formData)
      let result = await this.POST_RESEARCH_CUP_FILE(formData)
      if(result) {
        this.$message({title: 'Видео визитка загружена'})
      }
    }
  },
  computed: {
    ...mapState('studentResearchCupRegistration', ['researchCupSteps']),
  },
}
</script>

<style scoped>

</style>
