<template>
  <div class="container">
    <div class="row">
      <div class="col-md-12 mt-4 mb-4">
        <div class="card">
          <div class="card-body">
            <div class="card-title mb-4">
              <div class="row">
                <div class="col-12 ml-3">
                  <h2 class="d-block"
                      style="font-size: 1.3rem; font-weight: bold;">Информация о студенте</h2>
                </div>
              </div>
            </div>
            <div>
              <p><strong>ФИО:</strong> {{ studentRegistrationData?.last_name }} {{
                  studentRegistrationData?.first_name
                }} {{ studentRegistrationData?.middle_name }}</p>
              <p><strong>ИИН:</strong> {{ studentRegistrationData?.iin }}</p>
              <p><strong>Баркод:</strong> {{ studentRegistrationData?.barcode }}</p>
              <p><strong>Специальность или ОП:</strong> {{ studentRegistrationData?.education_speciality_code }}
                {{ studentRegistrationData?.education_speciality_name }}</p>
              <p><strong>Уровень обучения:</strong> {{ studentRegistrationData?.study_level }}</p>
              <p><strong>Курс:</strong> {{ studentRegistrationData?.study_course }}</p>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-12" v-if="!form.id">
        <div class="container" v-if="isRegistration">
<!--          <div class="row mt-2">-->
<!--            <div class="col-md-3">Направление темы проекта</div>-->
<!--            <div class="col-md-9"><select v-model="form.project_theme_direction_id" style="width: 50%"-->
<!--                                          class="form-select" :disabled="isDisabled">-->
<!--              <option v-for="(value, index) in projectThemeDirection" :value="value.id" :key="index">-->
<!--                {{ value.text }}-->
<!--              </option>-->
<!--            </select></div>-->
<!--          </div>-->
          <div class="row mt-2">
            <div class="col-md-3">Тема проекта</div>
            <div class="col-md-9"><input type="text" style="width: 50%" v-model="form.theme" :disabled="isDisabled"
                                         class="form-control"></div>
          </div>
          <div class="row mt-2">
            <div class="col-md-3">Комментарий</div>
            <div class="col-md-9">
              <textarea class="form-control" v-model="form.comment" style="width: 50%" rows="3"
                        :disabled="isDisabled"></textarea>
            </div>
          </div>

          <div v-if="!isDisabled">
            <div class="row mt-2">
              <div class="form-check">
                <input class="form-check-input" type="checkbox" v-model="agreeCheckbox">
                <label class="form-check-label">
                  Согласен с <a href="https://back.uib.kz/main-files/research_cup.pdf">ПОЛОЖЕНИЕМ О СТИПЕНДИАЛЬНОЙ ПРОГРАММЕ STUDENT RESEARCH CUP</a>
                </label>
              </div>
            </div>

            <div class="row mt-2">
              <div class="col-md-12">
                <button @click="registerResearchConference" class="btn btn-primary" :disabled="!agreeCheckbox||isDisabled">
                  Зарегистрироваться
                </button>
              </div>
            </div>
          </div>

          <div v-else class="mt-3">
            <h5>
              {{form.register_status}}
            </h5>
          </div>

        </div>
      </div>
      <div class="col-md-12">
        <div class="container">
          <ResearchCupVideo v-if="isVideo" :loaded="student_steps ? student_steps.find(i=>i.step_id=='1') : false"/>
          <ResearchCupArticleAndPresentation v-if="isArticle" message="статья загружена" title="Статья" :step="1" :loaded="student_steps ? student_steps.find(i=>i.step_id=='2') : false"/>
          <ResearchCupArticleAndPresentation v-if="isPresentation" message="презентация загружена" title="Презентация" :step="2" :loaded="student_steps ? student_steps.find(i=>i.step_id=='3') : false"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions, mapState} from 'vuex'
import ResearchCupVideo from '../../../components/research-cup-registration/ResearchCupVideo'
import ResearchCupArticleAndPresentation from '../../../components/research-cup-registration/ResearchCupArticleAndPresentation'

export default {
  name: "ResearchCupRegistration",
  components: {
    ResearchCupVideo,
    ResearchCupArticleAndPresentation
  },
  data() {
    return {
      form: {
        id: null,
        project_theme_direction_id: null,
        theme: '',
        comment: ''
      },
      isDisabled: false,
      agreeCheckbox: false,
    }
  },
  computed: {
    ...mapState('studentResearchCupRegistration', ['studentRegistrationData', 'projectThemeDirection','cupSchedule']),
    isRegistration(){
      return this.checkDate(2)
    },
    isVideo(){
      return this.checkDate(3)
    },
    isArticle(){
      return this.checkDate(5)
    },
    isPresentation(){
      return this.checkDate(8)
    },
  },
  methods: {
    ...mapActions('studentResearchCupRegistration', ['GET_STUDENT_RESEARCH_CUP_REGISTRATION', 'REGISTER_TO_RESEARCH_CUP', 'GET_PROJECT_THEME_DIRECTION', 'GET_RESEARCH_CUP_STEPS', 'GET_CUP_SCHEDULE']),
    checkDate(index){
      const start = this.getDate(index, 'start_date')
      const end = this.getDate(index, 'end_date')
      const date = Math.round(new Date().getTime()/1000)

      return (start < date) && (date < end)
    },
    async registerResearchConference() {
      const res = await this.REGISTER_TO_RESEARCH_CUP(this.form)
      if (res) {
        this.$message({title: 'Регистрация прошла успешна'})
      }
      await this.getStudentResearchCupRegistrationData()
    },
    async getStudentResearchCupRegistrationData() {
      await this.GET_STUDENT_RESEARCH_CUP_REGISTRATION()
      this.setFormDataFromServer()
    },
    setFormDataFromServer() {
      if (this.studentRegistrationData?.id) {
        //this.form.project_theme_direction_id = this.studentRegistrationData.project_theme_direction_id
        this.form.theme = this.studentRegistrationData.theme
        this.form.comment = this.studentRegistrationData.comment
        this.form.register_status = this.studentRegistrationData.register_status
        this.isDisabled = true
        this.id = this.studentRegistrationData.id
        this.student_steps = this.studentRegistrationData.student_steps
      }
    },
    getDate(index, type){
      if(this.cupSchedule[index]){
        return this.cupSchedule[index][type]
      }
      return 0
    }
  },
  async mounted() {
    await this.getStudentResearchCupRegistrationData()
    //await this.GET_PROJECT_THEME_DIRECTION()
    await this.GET_RESEARCH_CUP_STEPS()
    await this.GET_CUP_SCHEDULE()
    // this.loading = false
  }
}
</script>

<style scoped>

</style>
